<template>
    <div>
        <div v-show="modalClosed" class="uploads-group">
            <div v-if="tab !== 'display'">
                <div v-if="allowUpload && tab === 'upload'" class="card column uploads uploads-r-16_9 br c-lightest-bg m-b-4">
                    <div class="upload-content row row-middle row-collapse">
                        <div
                            v-if=" ! uploading && ! processing"
                            style="cursor: pointer; width: 100%; height: 100%;"
                            v-on:drop="handleFileSelect"
                            v-on:dragover="handleDragOver"
                            v-on:click="handleDropzoneClick"
                        >
                            <div class="row row-middle row-tall">
                                <div v-if=" ! error" class="column text-center">
                                    <div class="card-figure m-b-4 p-l-4 p-r-4">
                                        <span class="i-container i-tone-accent">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon i-size-xlarge" viewBox="0 0 512 512"><path d="M160 160c-17.7 0-32 14.3-32 32V320c0 17.7 14.3 32 32 32H288c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32H160zM352 288l44.9 29.9c2 1.3 4.4 2.1 6.8 2.1c6.8 0 12.3-5.5 12.3-12.3V204.3c0-6.8-5.5-12.3-12.3-12.3c-2.4 0-4.8 .7-6.8 2.1L352 224v64z"></path><path fill-opacity="0.4" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM128 192c0-17.7 14.3-32 32-32H288c17.7 0 32 14.3 32 32V320c0 17.7-14.3 32-32 32H160c-17.7 0-32-14.3-32-32V192zM396.9 317.9L352 288V224l44.9-29.9c2-1.3 4.4-2.1 6.8-2.1c6.8 0 12.3 5.5 12.3 12.3V307.7c0 6.8-5.5 12.3-12.3 12.3c-2.4 0-4.8-.7-6.8-2.1z"></path></svg>
                                        </span>
                                    </div>
                                    <div class="card-content">
                                        <h2 class="card-title f-s-5 f-w-bold m-b-1 f-w-regular">{{ trans('Drag & Drop') }}</h2>
                                        <p class="f-s-4 c-muted">{{ trans('or browse your computer for a file') }}</p>
                                    </div>
                                    <input id="browse" type="file" style="display: none;" v-on:change="handleFileSelect" ref="browse">
                                </div>
                                <div v-if="error" class="column text-center">
                                    <div class="card-figure m-b-4 p-l-4 p-r-4">
                                        <button v-on:click="removeFile" type="button" class="uploads-close ps--a">
                                            <span class="i-container i-filled-dark cu-p">
                                                <!-- /icons/remove.svg -->
                                                <svg class="icon i-size-medium" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><g fill="none" fill-rule="evenodd"><circle fill="#FFF" cx="16" cy="16" r="16"></circle><path fill="#AAB2BD" fill-rule="nonzero" d="M23 10.41L21.59 9 16 14.59 10.41 9 9 10.41 14.59 16 9 21.59 10.41 23 16 17.41 21.59 23 23 21.59 17.41 16z"></path></g></svg>
                                            </span>
                                        </button>
                                        <div class="i-container i-filled-error">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" class="icon i-size-large"><g fill="#FF4D4D" class="icon"><path d="M32 63C14.88 63 1 49.12 1 32 1 14.88 14.88 1 32 1c17.12 0 31 13.88 31 31 0 17.12-13.88 31-31 31zm0-2c16.016 0 29-12.984 29-29S48.016 3 32 3 3 15.984 3 32s12.984 29 29 29z"></path><path d="M32 52a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm-1-39v26h2V11z"></path></g></svg>
                                        </div>
                                    </div>
                                    <div class="card-content">
                                        <h4 class="f-w-bold f-s-3 m-b-0 c-error">{{ trans('Oops! Upload failed') }}</h4>
                                        <p class="f-s-3 m-0 c-medium-tint">
                                            {{ error }}
                                            <a v-on:click.prevent="removeFile" class="ul" href="#remove-file">{{ trans('Try Again.') }}</a>
                                        </p>
                                    </div>
                                    <input id="browse" type="file" style="display: none;" v-on:change="handleFileSelect" ref="browse">
                                </div>
                            </div>
                        </div>
                        <div v-if="uploading || processing" class="column text-center">
                            <div class="card-figure m-b-4">
                                <button v-on:click="removeFile" type="button" class="uploads-close ps--a">
                                    <span class="i-container i-filled-dark cu-p">
                                        <!-- /icons/remove.svg -->
                                        <svg class="icon i-size-medium" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><g fill="none" fill-rule="evenodd"><circle fill="#FFF" cx="16" cy="16" r="16"></circle><path fill="#AAB2BD" fill-rule="nonzero" d="M23 10.41L21.59 9 16 14.59 10.41 9 9 10.41 14.59 16 9 21.59 10.41 23 16 17.41 21.59 23 23 21.59 17.41 16z"></path></g></svg>
                                    </span>
                                </button>
                                <upload-progress-donut
                                    v-if="uploading"
                                    size="large"
                                    v-bind:percentage="uploadProgress"
                                ></upload-progress-donut>
                                <div v-if="processing" class="chartdonut chartdonut_animation--rotate-large chartdonut--large m-a">
                                    <svg width="53" height="53"><g><circle cx="50%" cy="50%" r="23" stroke-width="3" stroke="#02DCAA" fill="none" stroke-dashoffset="5"></circle></g></svg>
                                </div>
                            </div>
                            <div v-if="uploading" class="card-content">
                                <h4 class="f-w-bold f-s-3 m-b-0">{{ trans('Uploading :fileName', {'fileName': uploadFileName}) }}</h4>
                                <p class="f-s-3 m-0 c-medium-tint">{{ trans(':uploadedMB of :totalMB', {'uploaded': uploadMbSent, 'total': uploadMbTotal}) }}</p>
                            </div>
                            <div v-if="processing" class="card-content">
                                <h4 class="f-w-bold f-s-3 m-b-0">{{ trans('Processing :fileName', {'fileName': uploadFileName}) }}</h4>
                                <p class="f-s-3 m-0 c-medium-tint">
                                    <!-- /icons/check.svg -->
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="icon i-size-small m-r-1"><g fill="#24D7C5"><path d="M19 4H5c-.6 0-1 .4-1 1v14c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V5c0-.6-.4-1-1-1zm-1 2v9h-3c-.6 0-1 .4-1 1v1h-4v-1c0-.6-.4-1-1-1H6V6h12z"></path><path d="M10.3 13.7c.5.5 1 .4 1.4 0l4-4c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L11 11.6l-1.3-1.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l2 2z"></path></g></svg>
                                    {{ trans('Uploaded') }}
                                </p>
                                <p v-if="safeToSave" class="f-s-3 m-0 c-medium-tint">{{ trans('It is now safe to save your content. Processing will continue in the background.') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="tab === 'url'" class="card column uploads uploads--min-height-250 uploads-r-16_9 br c-lightest-bg m-b-4">
                    <external-video-url
                        v-on:url:changed="changeExternalVideoUrl"
                    ></external-video-url>
                </div>
                <div v-if="tab === 'wistia'" class="card column uploads uploads--min-height-250 uploads-r-16_9 br c-lightest-bg m-b-4">
                    <wistia-upload
                        v-bind:api-key="wistiaKey"
                        v-on:video:uploaded="movedFromWistia"
                    ></wistia-upload>
                </div>
                <nav v-if="allowUpload" class="nav">
                    <a v-bind:class="{'header-nav--active' : tab === 'upload'}" v-on:click.prevent="tab = 'upload'" class="header-nav--link f-s-3 m-r-3" href="#upload">{{ trans('Upload File') }}</a>
                    <a v-bind:class="{'header-nav--active' : tab === 'url'}" v-on:click.prevent="tab = 'url'" class="header-nav--link f-s-3 m-l-3 m-r-3" href="#url">{{ trans('External Video URL') }}</a>
                    <!-- <a v-bind:class="{'header-nav--active' : tab === 'wistia'}" v-on:click.prevent="tab = 'wistia'" class="header-nav--link f-s-3 m-l-3 m-r-3" href="#wistia">{{ trans('Move from Wistia') }}</a> -->
                </nav>
            </div>

            <div v-if="tab === 'display'" class="br ps--r">
                <div class="row row-center">
                    <button v-on:click="removeFile" type="button" class="uploads-close ps--a">
                        <span class="i-container i-filled-dark cu-p">
                            <!-- /icons/remove.svg -->
                            <svg class="icon i-size-medium" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><g fill="none" fill-rule="evenodd"><circle fill="#FFF" cx="16" cy="16" r="16"></circle><path fill="#AAB2BD" fill-rule="nonzero" d="M23 10.41L21.59 9 16 14.59 10.41 9 9 10.41 14.59 16 9 21.59 10.41 23 16 17.41 21.59 23 23 21.59 17.41 16z"></path></g></svg>
                        </span>
                    </button>
                    <button v-if="videoType === 'vimeo' || videoType === 'bunny'" v-on:click="changeThumbnail" type="button" class="uploads-thumb ps--a f-s-2 c-white-bg h-c-muted cu-p">
                        <span>{{ trans('Replace thumbnail') }}</span>
                    </button>
                    <div class="column text-center p-l-0 p-r-0">
                        <div v-if="showThumbnailWarning" class="cover-image cover-image-overlay">
                            <div class="cover-overlay" style="background-color: rgba(0,0,0,0.5);"></div>
                            <img v-bind:src="previewImageUrl" class="image-fit" v-original-fallback />
                            <div class="cover-content cover-content--vertical-middle">
                                <p class="cover-description f-s-4 c-bright">{{ trans('Thumbnail will be updated after you save the page.') }}</p>
                            </div>
                        </div>
                        <div class="video">
                            <div ref="player" v-bind:id="fieldName + '_player'"></div>
                        </div>
                    </div>
                </div>
            </div>
            <input type="hidden" v-bind:name="fieldName" v-model="fileUrl" />
            <input v-if="videoType === 'vimeo' || videoType === 'bunny'" type="hidden" name="length" v-bind:value="duration" />
            <input type="hidden" name="video_thumbnail_url" v-bind:value="thumbnailUrl" />
            <input type="hidden" name="video_image" v-bind:value="imageUrl" />
            <input type="hidden" name="video_type" v-bind:value="videoType" />
            <input type="hidden" name="video_size" v-bind:value="uploadMbTotal" />
        </div>

        <video-thumb-upload-modal
            ref="video-thumb-upload-modal"
            v-bind:existing-video-image="existingVideoImage"
            v-on:video:thumb:select="thumbnailSelected"
            v-on:open="thumbnailModalToggle(true)"
            v-on:close="thumbnailModalToggle(false)"
        ></video-thumb-upload-modal>
    </div>
</template>
<script>
    import VideoUpload from './VideoUpload.vue';
    import VideoThumbUploadModal from '../modals/VideoThumbUploadModal.vue';
    import { EventBus } from '../../event-bus.js';

    export default {
        extends: VideoUpload,
        components: {
            VideoThumbUploadModal,
        },
        props: {
            existingVideoImage: {
                type: String,
                default: null
            },
            length: {
                type: String,
                default: null
            },
        },
        data() {
            return {
                duration: _.get(this, 'length'),

                thumbnailUrl: null,
                imageUrl: this.existingVideoImage ? this.existingVideoImage : '',

                modalClosed: true,
            }
        },
        computed: {
            showThumbnailWarning() {
                return this.imageUrl !== this.existingVideoImage;
            },
            previewImageUrl() {
                if (this.imageUrl) {
                    return window.Kourses.assetBaseUrl + this.imageUrl;
                } else if (this.existingVideoImage) {
                    return window.Kourses.assetBaseUrl + this.existingVideoImage;
                }

                return null;
            },
        },
        methods: {
            removeFile() {
                // Remove player
                if (typeof this.$refs['player'] !== 'undefined') {
                    this.$refs['player'].firstChild.remove();
                }

                this.tab = this.allowUpload ? 'upload' : 'url';
                this.fileUrl    = '';
                this.fileName   = '';
                this.metadata   = null;
                this.uploading  = false;
                this.processing = false;
                this.error      = null;
                this.duration   = null;
                this.safeToSave = false;
                this.uploadProgress = 0;
                this.uploadFileName = null;
                this.uploadMbSent = 0;
                this.uploadMbTotal = 0;
                this.videoType = 'none';

                this.terminateExistingUpload();

                EventBus.$emit(this.fieldName + ':video:exists', false);
            },
            changeThumbnail() {
                this.$refs['video-thumb-upload-modal'].show = true;
                this.$refs['video-thumb-upload-modal'].saving = false;
            },
            onUploadTranscoded(thumbnail, length) {
                this.tab = 'display';
                this.duration = length;
                this.processing = false;
                this.thumbnailUrl = thumbnail;
                this.$refs['video-thumb-upload-modal'].videoImageUrl = thumbnail;

                Vue.nextTick(() => {
                    if (this.videoType === 'vimeo') {
                        this.createVimeoPlayer(this.fileUrl, true).ready().then(() => {
                            this.$emit('video-selected', this.fileUrl);
                        })
                        .catch(() => {
                            this.processing = true;
                            this.tab = 'upload';
                        });
                    } else if (this.videoType === 'bunny') {
                        this.createBunnyIframe(this.fileUrl);
                    }
                });
            },
            thumbnailSelected(type, image) {
                this.imageUrl = image;

                EventBus.$emit('form:element:changed', 'image', image);
            },
            thumbnailModalToggle(open) {
                this.modalClosed = ! open;
            }
        }
    }
</script>