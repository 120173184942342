<template>
    <div>
        <div class="row m-t-6">
            <aside class="column small-12 medium-3">
                <div class="card m-b-4 p-4 br">
                    <div class="form-group form-group-compact">
                        <label class="sc-reader">{{ trans('Search') }}</label>
                        <div class="form-group-addon">
                            <input class="input__field input-small" type="text" v-bind:placeholder="trans('Search Members')" v-model="selectedQuery" v-on:keydown.enter="filterMembers">
                            <span class="form-append i-container btn btn-text i-filled-dark width-auto-min p-r-0">
                                <!-- /icons/search.svg -->
                                <svg class="icon m-l-1 i-size-small" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M16.7 15.3c.9-1.2 1.4-2.6 1.4-4.2 0-3.9-3.1-7.1-7-7.1S4 7.2 4 11.1c0 3.9 3.2 7.1 7.1 7.1 1.6 0 3.1-.5 4.2-1.4l3 3c.2.2.5.3.7.3.2 0 .5-.1.7-.3.4-.4.4-1 0-1.4l-3-3.1zm-5.6.8c-2.8 0-5.1-2.2-5.1-5S8.3 6 11.1 6s5.1 2.3 5.1 5.1-2.3 5-5.1 5z" class="icon"/></svg>
                            </span>
                        </div>
                    </div>
                    <div class="form-group form-group-compact">
                        <label class="sc-reader">{{ trans('Filter by') }}</label>
                        <select class="input-small input__field" v-model="selectedFilter">
                            <option v-bind:value="null">{{ trans('Filter by ...') }}</option>
                            <option value="hub">{{ trans('Filter by Hub') }}</option>
                            <option value="membership">{{ trans('Filter by Membership') }}</option>
                            <option value="status">{{ trans('Filter by Member Status') }}</option>
                            <option value="account_type">{{ trans('Filter by Account Type') }}</option>
                        </select>
                    </div>
                    <div v-if="selectedFilter === 'hub'" class="form-group form-group-compact">
                        <label class="sc-reader">{{ trans('Hub') }}</label>
                        <select class="input-small input__field" v-model="selectedHub">
                            <option v-bind:value="null">{{ trans('All Hubs') }}</option>
                            <option v-for="hub in hubs" v-bind:value="hub.encoded_id" v-bind:key="hub.encoded_id">{{ hub.title }}</option>
                        </select>
                    </div>
                    <div v-if="selectedFilter === 'membership'" class="form-group form-group-compact">
                        <label class="sc-reader">{{ trans('Membership') }}</label>
                        <select class="input-small input__field" v-model="selectedMembership">
                            <option v-bind:value="null">{{ trans('All Memberships') }}</option>
                            <option v-for="membership in memberships" v-bind:value="membership.encoded_id" v-bind:key="membership.encoded_id">{{ membership.name }}</option>
                        </select>
                    </div>
                    <div v-if="selectedFilter === 'status'" class="form-group form-group-compact">
                        <label class="sc-reader">{{ trans('Member Status') }}</label>
                        <select class="input-small input__field" v-model="selectedStatus">
                            <option v-bind:value="null">{{ trans('All States') }}</option>
                            <option v-for="status in states" v-bind:value="status.key" v-bind:key="status.key">{{ status.title }}</option>
                        </select>
                    </div>
                    <div v-if="selectedFilter === 'account_type'" class="form-group form-group-compact">
                        <label class="sc-reader">{{ trans('Account Type') }}</label>
                        <select class="input-small input__field" v-model="selectedAccountType">
                            <option v-bind:value="null">{{ trans('All Types') }}</option>
                            <option v-for="accountType in accountTypes" v-bind:value="accountType.type" v-bind:key="accountType.type">{{ accountType.label }}</option>
                        </select>
                    </div>
                    <div class="form-group form-group-compact">
                        <label class="sc-reader">{{ trans('Time Period') }}</label>
                        <select class="input-small input__field" v-model="selectedTimePeriod">
                            <option value="">{{ trans('Time Period') }}</option>
                            <option value="today">{{ trans('Today') }}</option>
                            <option value="yesterday">{{ trans('Yesterday') }}</option>
                            <option value="current_week">{{ trans('Current Week') }}</option>
                            <option value="last_week">{{ trans('Last Week') }}</option>
                            <option value="current_month">{{ trans('Current Month') }}</option>
                            <option value="last_month">{{ trans('Last Month') }}</option>
                            <option value="custom">{{ trans('Custom Period') }}</option>
                        </select>
                    </div>
                    <div v-if="selectedTimePeriod === 'custom'">
                        <div class="form-group m-b-0" v-bind:class="{'has-error': dateError}">
                            <date-pick
                                v-model="dateFrom"
                                v-bind:input-attributes="{'class': 'input__field input__field--date', 'placeholder': trans('Pick a date')}"
                                v-on:input="validateDates"
                                v-bind:nextMonthCaption="datePickLabels.nextMonthCaption"
                                v-bind:prevMonthCaption="datePickLabels.prevMonthCaption"
                                v-bind:setTimeCaption="datePickLabels.setTimeCaption"
                                v-bind:weekdays="datePickLabels.weekdays"
                                v-bind:months="datePickLabels.months"
                            ></date-pick>
                        </div>
                        <div class="form-group" v-bind:class="{'has-error': dateError}">
                            <date-pick
                                v-model="dateTo"
                                v-bind:input-attributes="{'class': 'input__field input__field--date', 'placeholder': trans('Pick a date')}"
                                v-on:input="validateDates"
                                v-bind:nextMonthCaption="datePickLabels.nextMonthCaption"
                                v-bind:prevMonthCaption="datePickLabels.prevMonthCaption"
                                v-bind:setTimeCaption="datePickLabels.setTimeCaption"
                                v-bind:weekdays="datePickLabels.weekdays"
                                v-bind:months="datePickLabels.months"
                            ></date-pick>
                        </div>
                        <div v-if="dateError" class="form-help">
                            {{ trans('Invalid Date Range') }}
                        </div>
                    </div>
                    <button v-on:click="filterMembers" v-bind:class="{'btn-loading': searching}" type="button" class="btn btn-primary btn--block m-b-2">
                        {{ trans('Search') }}
                        <div v-if="searching" class="loader">
                            <div class="chartdonut chartdonut_animation--rotate-tiny chartdonut--tiny">
                                <svg class="i-stroke-bright" width="24" height="24"><g><circle class="icon" cx="50%" cy="50%" r="10" stroke-width="2" fill="none" stroke-dashoffset="5"></circle></g></svg>
                            </div>
                        </div>
                    </button>
                    <div class="text-center">
                        <button v-on:click="resetFilter" type="button" class="btn btn-text btn-text--muted width-auto-min">{{ trans('Reset Filter') }}</button>
                    </div>
                </div>
            </aside>
            <div class="member-content column small-12 medium-9">
                <div class="member-list-group">
                    <div class="members-list-header row row-collapse row-middle br-bottom m-b-5 p-b-4">
                        <div class="column text-left">
                            <span v-if="loading" class="c-medium m-r-5 f-s-3">{{ trans('Loading members...') }}</span>
                            <span v-else class="c-medium m-r-5 f-s-3">{{ trans(':num members', {'num': paginator.total}) }}</span>
                        </div>
                        <div class="column text-right">
                            <a class="btn btn-text btn-text--muted c-muted f-s-3" v-bind:href="exportUrl">{{ trans('Export members') }}</a>
                        </div>
                    </div>
                    <div class="member-list-content">
                        <div v-if="loading" style="margin: 0 auto;" class="chartdonut chartdonut_animation--rotate-large chartdonut--large">
                            <svg id="svgElement" width="53" height="53"><g><circle cx="50%" cy="50%" r="23" stroke-width="3" stroke="#02DCAA" fill="none" stroke-dashoffset="5"></circle></g></svg>
                        </div>
                        <div v-else>
                            <section v-for="(group, letter, index) in letterGroups" v-bind:key="index" class="member-list-group-item row row-collapse m-b-5">
                                <div class="column">
                                    <div v-if="index > 0" class="br-top m-b-5"></div>
                                    <div class="slat">
                                        <div class="member-list-index p-t-3">
                                            <div class="br i-size-large p-2 rounded-xlarge text-center">
                                                <h4 class="m-t-1 p-t-micro f-w-regular">{{ letter }}</h4>
                                            </div>
                                        </div>
                                        <div v-for="member in group" v-bind:key="member.id" class="p-3 rounded-ittybitty row row-middle h-anim h-bg-accent--light">
                                            <div>
                                                <a v-bind:href="memberShowLink(member)">
                                                    <div class="avatar">
                                                        <img v-if="member.image" class="avatar-image avatar-medium" v-bind:src="member.image_url.small">
                                                        <avatar-img v-else class="avatar-image avatar-medium" v-bind:src="getGravatarUrl(member.email)"></avatar-img>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="slat-content column">
                                                <a v-bind:href="memberShowLink(member)">
                                                    <h4 class="f-s-4 m-b-1 c-primary">
                                                        <span class="m-r-1">{{ member.full_name }}</span>
                                                        <span v-if="showAccountTypeBadge(member)" class="badge c-muted-dim-bg c-bright f-w-bold f-caps m-r-1">{{ member.account_type }}</span>
                                                        <span class="f-s-2 f-w-regular c-medium-tint">{{ member.email }}</span>
                                                    </h4>
                                                </a>
                                                <p class="f-s-3 m-0 c-medium-tint">{{ trans('Joined') }} {{ memberJoinedDate(member) }}</p>
                                            </div>
                                            <div class="slat-aside text-right">
                                                <div class="more-group inline m-l-4">
                                                    <member-dropdown-menu
                                                        v-bind:member="member"
                                                        v-bind:can-delete-members="canDeleteMembers"
                                                    ></member-dropdown-menu>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <paginator
            v-if=" ! loading"
            v-bind:to="paginator.to"
            v-bind:from="paginator.from"
            v-bind:total="paginator.total"
            v-bind:last-page="paginator.lastPage"
            v-bind:current-page="paginator.currentPage"
            v-on:paginator:switch-page="changePage"
        ></paginator>
    </div>
</template>
<script>
import { memberShowLink } from '../routes';
import { getGravatarUrlFromEmail } from '../helpers';
import Paginator from './Paginator.vue';
import AvatarImg from './AvatarImg.vue';
import MemberDropdownMenu from './members/MemberDropdownMenu.vue';
import { MEMBER_ACCOUNT_TYPE_REGULAR } from '../constants';
import DatePick from 'vue-date-pick';
import DatePickLocalizationMixin from './fields/DatePickLocalizationMixin.vue';

export default {
    components: {
        Paginator,
        AvatarImg,
        MemberDropdownMenu,
        DatePick,
    },
    mixins: [
        DatePickLocalizationMixin
    ],
    props: {
        members: {
            type: Object,
        },
        hubs: {
            type: Array,
        },
        memberships: {
            type: Array,
        },
        hub: {
            type: String,
            default: null,
        },
        query: {
            type: String,
            default: null,
        },
        membership: {
            type: String,
            default: null,
        },
        status: {
            type: String,
            default: null,
        },
        canDeleteMembers: {
            type: Boolean,
            default: false,
        },
        accountTypes: {
            type: [Array, Object],
            required: true,
        },
        accountType: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            items: _.get(this.members, 'data', []),
            letterGroups: {},
            selectedHub: this.hub,
            selectedMembership: this.membership,
            selectedStatus: this.status,
            selectedAccountType: this.accountType || null,
            paginator: {
                to: _.get(this.members, 'to', 1),
                from: _.get(this.members, 'from', 1),
                total: _.get(this.members, 'total', 1),
                lastPage: _.get(this.members, 'last_page', 1),
                currentPage: _.get(this.members, 'current_page', 1),
            },
            loading: false,
            searching: false,
            selectedQuery: this.query,
            selectedFilter: this.getActiveFilter(),

            states: [
                {
                    key: 'account_activated',
                    title: this.trans('Account activated')
                },
                {
                    key: 'account_not_activated',
                    title: this.trans('Account not activated')
                },
                {
                    key: 'logged_in_within_30_days',
                    title: this.trans('Logged in within 30 days')
                },
                {
                    key: 'not_logged_in_within_30_days',
                    title: this.trans('Not logged in within 30 days')
                },
            ],

            selectedTimePeriod: "",
            dateTo: null,
            dateFrom: null,
            dateError: false,
        }
    },
    computed: {
        exportUrl() {
            return window.Kourses.activeBaseUrl + '/members/export'
                + '?hub=' + (this.selectedHub || '')
                + '&membership=' + (this.selectedMembership || '')
                + '&time_period=' + this.selectedTimePeriod + '&date_to=' + (this.dateTo ? this.dateTo : '') + '&date_from=' + (this.dateFrom ? this.dateFrom : '')
                + '&account_type=' + (this.selectedAccountType || '')
                + '&status=' + (this.selectedStatus || '')
                + '&query=' + (this.selectedQuery || '');
        },
    },
    watch: {
        selectedFilter() {
            this.selectedHub = this.selectedMembership = this.selectedStatus = null;
        }
    },
    methods: {
        memberShowLink(member) {
            return memberShowLink(member);
        },
        getGravatarUrl(email) {
            return getGravatarUrlFromEmail(email);
        },
        splitIntoLetterGroups(members) {
            let groups = {};

            for (var member of members) {
                let firstChar = member.last_name.charAt(0).toUpperCase();

                // We need to initialize "letter group" as empty array
                if ( ! _.has(groups, firstChar)) {
                    groups[firstChar] = [];
                }

                groups[firstChar].push(member);
            }

            return groups;
        },
        memberJoinedDate(member) {
            let joinedDate = new Date(member.created_at);

            return joinedDate.toLocaleDateString(window.Kourses.locale, {
                day: 'numeric',
                month: 'long',
                year: 'numeric'
            });
        },
        changePage(page) {
            this.loading = true;

            this.getMembers(page).then(this.showMembers);
        },
        filterMembers() {
            this.loading = true;
            this.searching = true;

            this.getMembers(1).then(this.showMembers);
        },
        resetFilter() {
            this.loading = true;
            this.selectedQuery = null;
            this.selectedFilter = null;
            this.selectedHub = null;
            this.selectedMembership = null;
            this.selectedAccountType = null;
            this.selectedStatus = null;
            this.selectedTimePeriod = "";
            this.dateError = false;
            this.dateFrom = null;
            this.dateTo = null;

            this.getMembers(1).then(this.showMembers);
        },
        showMembers(response) {
            this.paginator = {
                to: response.data.to,
                from: response.data.from,
                total: response.data.total,
                lastPage: response.data.last_page,
                currentPage: response.data.current_page
            };
            this.items = response.data.data;
            this.letterGroups = this.splitIntoLetterGroups(this.items);
            this.loading = false;
            this.searching = false;
        },
        /**
         * Return active filter typ.
         *
         * @return  {String|Null}
         */
        getActiveFilter() {
            if (this.hub) {
                return 'hub';
            }

            if (this.membership) {
                return 'membership';
            }

            if (this.status) {
                return 'status';
            }

            if (this.accountType) {
                return 'account_type';
            }

            return null;
        },
        /**
         * Return member fetch promise.
         *
         * @param   {Number}  page
         *
         * @return  {Promise}
         */
        getMembers(page = 1) {
            return window.axios.get(Kourses.apiBaseUrl + '/members',{
                params: {
                    'page': page,
                    'query': this.selectedQuery,
                    'hub': this.selectedHub,
                    'membership': this.selectedMembership,
                    'account_type': this.selectedAccountType,
                    'status': this.selectedStatus,
                    'date_to': this.dateTo,
                    'date_from': this.dateFrom,
                    'time_period': this.selectedTimePeriod
                }
            })
        },
        /**
         * Show account type badge if member is not a regular member.
         *
         * @param   {Object}  member
         *
         * @return  {Boolean}
         */
        showAccountTypeBadge(member) {
            return member.account_type !== MEMBER_ACCOUNT_TYPE_REGULAR;
        },
        /**
         * Validate date range and set "dateError" flag.
         *
         * @return {Void}
         */
        validateDates() {
            if (null === this.dateFrom || null === this.dateTo) {
                this.dateError = false;
            } else {
                let from = new Date(this.dateFrom);
                let to = new Date(this.dateTo);

                if (from > to) {
                    this.dateError = true;
                } else {
                    this.dateError = false;
                }
            }
        }
    },
    created() {
        this.letterGroups = this.splitIntoLetterGroups(this.items);
    },
}
</script>