<template>
    <div>
        <image-checkbox
            v-if="showTypeField"
            v-bind:selected="type"
            v-bind:items="filteredTypes"
            v-on:selected="changeType"
            name="type"
        ></image-checkbox>

        <div v-if="editMode" v-bind:class="{'has-error': errors.has('name')}" class="form-group">
            <label>
                {{ trans('Name') }}
                <input class="input__field" type="text" v-model="name" v-on:keyup="manuallyChangedName = true">
            </label>
        </div>
        <div v-if="errors.has('name')" class="form-help">{{ errors.get('name') }}</div>

        <div v-if="editMode" class="form-group">
            <label>
                {{ trans('Description') }}
                <input class="input__field" type="text" v-model="description">
            </label>
        </div>

        <div v-if="type !== 'free' && allowInput" class="row row-collapse-indent">
            <div v-bind:class="{'small-4': type === 'installment-plan', 'small-6': type !== 'installment-plan'}" class="column">
                <div class="form-group form-group-addon">
                    <label>
                        {{ trans('Price') }}
                        <input class="input__field" type="number" step="any" v-model="price" placeholder="0">
                    </label>
                    <span class="btn form-append btn-text width-auto-min">{{ currency }}</span>
                </div>
            </div>
            <div v-if="isRecurringType" v-bind:class="{'small-4': type === 'installment-plan', 'small-6': type === 'subscription'}" class="column">
                <div class="form-group">
                    <label>{{ trans('Every') }}</label>
                    <select v-on:change="generateName" class="input__field" v-model="interval">
                        <option value="day">{{ trans('day') }}</option>
                        <option value="week">{{ trans('week') }}</option>
                        <option value="2-weeks">{{ trans('every 2 weeks') }}</option>
                        <option value="month">{{ trans('month') }}</option>
                        <option value="quarter">{{ trans('quarter') }}</option>
                        <option value="6-months">{{ trans('every 6 months') }}</option>
                        <option value="year">{{ trans('year') }}</option>
                    </select>
                </div>
            </div>
            <div v-if="type === 'installment-plan'" class="column small-4">
                <div class="form-group" v-bind:class="{'has-error': errors.has('installments')}">
                    <label>
                        {{ trans('Number of payments') }}
                        <input class="input__field" type="number" v-model="intervalCount" placeholder="0" min="2">
                    </label>
                </div>
                <div v-if="errors.has('installments')" class="form-help">{{ errors.get('installments') }}</div>
            </div>
        </div>
        <div v-if="isRecurringType && allowInput" class="row row-collapse-indent">
            <div class="column small-6">
                <div class="form-group">
                    <label>
                        {{ trans('Trial Period (Days)') }}
                        <input class="input__field" type="number" v-model="trialPeriodDays" placeholder="0" step="1" max="730">
                    </label>
                </div>
                <div v-if="errors.has('trial_period_days')" class="form-help">{{ errors.get('trial_period_days') }}</div>
            </div>
            <div class="column small-6">
                <div class="form-group form-group-addon">
                    <label>
                        {{ trans('Setup fee (Charged today)') }}
                        <input class="input__field" type="number" step="any" v-model="setupPrice" placeholder="0">
                    </label>
                    <span class="btn form-append btn-text width-auto-min">{{ currency }}</span>
                </div>
            </div>
        </div>

        <div v-if="fieldNamePrefix">
            <input type="hidden" v-bind:name="fieldNamePrefix + '[id]'" v-bind:value="id" />
            <input type="hidden" v-bind:name="fieldNamePrefix + '[name]'" v-bind:value="name" />
            <input type="hidden" v-bind:name="fieldNamePrefix + '[description]'" v-bind:value="description" />
            <input type="hidden" v-bind:name="fieldNamePrefix + '[type]'" v-bind:value="type" />
            <input type="hidden" v-bind:name="fieldNamePrefix + '[price]'" v-bind:value="price" />
            <input type="hidden" v-bind:name="fieldNamePrefix + '[setup_price]'" v-bind:value="setupPrice" />
            <input type="hidden" v-bind:name="fieldNamePrefix + '[currency]'" v-bind:value="currency" />
            <input type="hidden" v-bind:name="fieldNamePrefix + '[interval]'" v-bind:value="interval" />
            <input type="hidden" v-bind:name="fieldNamePrefix + '[interval_count]'" v-bind:value="intervalCount" />
            <input type="hidden" v-bind:name="fieldNamePrefix + '[trial_period_days]'" v-bind:value="trialPeriodDays" />
        </div>
    </div>
</template>
<script>
import { PLAN_TYPE_FREE, PLAN_TYPE_INSTALLMENT_PLAN, PLAN_TYPE_ONE_TIME, PLAN_TYPE_SUBSCRIPTION } from '../../constants.js';
import Errors from '../../errors.js';

export default {
    props: {
        plan: {
            type: Object
        },
        fieldNamePrefix: {
            type: String,
            default: null
        },
        showTypeField: {
            type: Boolean,
            default: true
        },
        upsellPlan: {
            type: Boolean,
            default: false
        },
        allowInput: {
            type: Boolean,
            default: false
        },
        editMode: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Object,
            default: () => {
                return new Errors();
            }
        },
        selectedType: {
            type: String,
            default: PLAN_TYPE_FREE,
        },
        allowedTypes: {
            type: Array,
            default: () => [PLAN_TYPE_FREE, PLAN_TYPE_ONE_TIME, PLAN_TYPE_SUBSCRIPTION, PLAN_TYPE_INSTALLMENT_PLAN],
        },
    },
    data() {
        return {
            id: _.get(this.plan, 'id', null),
            name: _.get(this.plan, 'name', 'Free'),
            description: _.get(this.plan, 'description', ''),
            type: _.get(this.plan, 'type', this.selectedType),
            price: _.has(this.plan, 'price') ? _.get(this.plan, 'price') / 100 : null,
            setupPrice: _.has(this.plan, 'setup_price') ? _.get(this.plan, 'setup_price') / 100 : null,
            currency: _.get(this.plan, 'currency', window.Kourses.currency),
            interval: _.get(this.plan, 'interval', 'month'),
            intervalCount: _.get(this.plan, 'interval_count', ''),
            trialPeriodDays: _.get(this.plan, 'trial_period_days', ''),

            types: {
                [PLAN_TYPE_FREE]: {
                    title: this.trans('Free'),
                    description: this.trans('No payment'),
                    image: Kourses.baseUrl + '/img/pricing-plan-free.png'
                },
                [PLAN_TYPE_ONE_TIME]: {
                    title: this.trans('One-time Payment'),
                    description: this.trans('Charged upon signup'),
                    image: Kourses.baseUrl + '/img/pricing-plan-one-time.png'
                },
                [PLAN_TYPE_SUBSCRIPTION]: {
                    title: this.trans('Subscription'),
                    description: this.trans('Charged on regular intervals'),
                    image: Kourses.baseUrl + '/img/pricing-plan-subscription.png'
                },
                [PLAN_TYPE_INSTALLMENT_PLAN]: {
                    title: this.trans('Installment Plan'),
                    description: this.trans('Charged over preset schedule'),
                    image: Kourses.baseUrl + '/img/pricing-plan-installment-plan.png'
                }
            },
            manuallyChangedName: false,
        }
    },
    watch: {
        plan: function(value) {
            if ( ! _.isEmpty(value) && this.upsellPlan === false) {
                this.setPlan(value);
            }
        },
        selectedType: function (value) {
            if (typeof plan == 'undefined') {
                this.type = value;
            }
        },
    },
    computed: {
        isRecurringType() {
            return [PLAN_TYPE_SUBSCRIPTION, PLAN_TYPE_INSTALLMENT_PLAN].includes(this.type);
        },
        filteredTypes() {
            let filteredTypes = {...this.types};

            Object.keys(filteredTypes).forEach(type => {
                if ( ! this.allowedTypes.includes(type)) {
                    delete filteredTypes[type];
                }
            });

            return filteredTypes;
        },
    },
    methods: {
        changeType(type) {
            this.type = type;

            this.generateName();

            this.$emit('type:changed', type);
        },
        setPlan(plan) {
            this.id = plan.id;
            this.name = plan.name;
            this.description= plan.description;
            this.type = plan.type;
            this.price = plan.price;
            this.setupPrice = plan.setup_price;
            this.currency = plan.currency;
            this.interval = plan.interval;
            this.intervalCount = plan.interval_count;
            this.trialPeriodDays = plan.trial_period_days;
        },
        resetPlan() {
            this.id = null;
            this.name = '';
            this.description= '';
            this.type = this.selectedType;
            this.price = null;
            this.setupPrice = null;
            this.currency = window.Kourses.currency;
            this.interval = 'month';
            this.intervalCount = null;
            this.trialPeriodDays = null;
        },
        addPlan() {
            this.$emit('add-pricing-plan', {
                id: this.id,
                name: this.name,
                description: this.description,
                type: this.type,
                price: this.price,
                setup_price: this.setupPrice,
                currency: this.currency,
                interval: this.interval,
                interval_count: this.intervalCount,
                trial_period_days: this.trialPeriodDays
            });

            this.resetPlan();
        },
        generateName() {
            if (this.manuallyChangedName) {
                return;
            }

            let newName = this.trans('Free');

            if (this.type === PLAN_TYPE_ONE_TIME) {
                newName = this.trans('One-Time');
            } else if (this.type === PLAN_TYPE_INSTALLMENT_PLAN) {
                newName = this.trans('Installments');
            } else if (this.type === PLAN_TYPE_SUBSCRIPTION) {
                if (this.interval === 'day') {
                    newName = this.trans('Daily Subscription');
                } else if (this.interval === 'week') {
                    newName = this.trans('Weekly Subscription');
                } else if (this.interval === '2-weeks') {
                    newName = this.trans('Bi-weekly Subscription');
                } else if (this.interval === 'month') {
                    newName = this.trans('Monthly Subscription');
                } else if (this.interval === 'quarter') {
                    newName = this.trans('Quarterly Subscription');
                } else if (this.interval === '6-months') {
                    newName = this.trans('Bi-annual Subscription');
                } else if (this.interval === 'year') {
                    newName = this.trans('Yearly Subscription');
                }
            }

            this.name = newName;
        }
    }
}
</script>