<template>
    <modal title="Edit plan" v-bind:show.sync="show" ref="modal">
        <pricing-plan
            v-bind:plan="plan"
            v-bind:errors="errors"
            v-bind:show-type-field="false"
            ref="pricing-plan"
            v-bind:allow-input="allowInput"
            v-bind:edit-mode="true"
        ></pricing-plan>

        <div slot="footer">
            <div class="row row-collapse">
                <div class="column">
                    <button type="button" class="btn btn-primary m-r-3" v-on:click="savePlan">{{ trans('Save') }}</button>
                </div>
                <div class="text-right">
                    <button type="button" class="btn btn-outline btn-outline--alert" v-on:click="deletePlan">{{ trans('Delete') }}</button>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
    import Modal from './Modal.vue';
    import Errors from '../../errors.js';
    import { PLAN_TYPE_INSTALLMENT_PLAN, PLAN_TYPE_SUBSCRIPTION } from '../../constants.js';

    export default {
        components: {
            Modal
        },
        props: {
            allowInput: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                plan: {},
                show: false,
                index: null,
                errors: new Errors()
            }
        },
        methods: {
            close() {
                this.$emit('close');
            },
            savePlan() {
                let plan = _.clone(this.$refs['pricing-plan'].$data);
                plan.interval_count = plan.intervalCount;
                plan.trial_period_days = plan.trialPeriodDays;
                plan.setup_price = plan.setupPrice;

                if (this.validatePlan(plan)) {
                    this.$emit('save', plan, this.index);
                }
            },
            validatePlan(plan) {
                this.errors.clear();

                if (plan.name == null || plan.name === '') {
                    this.errors.set('name', this.trans('Plan name is required.'));

                    return false;
                }

                if (plan.type === PLAN_TYPE_INSTALLMENT_PLAN && (plan.interval_count === '' || parseInt(plan.interval_count) < 2)) {
                    this.errors.set('installments', this.trans('Number of monthly payments needs to be at least 2'));

                    return false;
                }

                if ((plan.type === PLAN_TYPE_INSTALLMENT_PLAN || plan.type === PLAN_TYPE_SUBSCRIPTION)
                && plan.trial_period_days !== '' && parseInt(plan.trial_period_days) > 730) {
                    this.errors.set('trial_period_days', this.trans('Trial period cannot exceed 2 years (730 days)'));

                    return false;
                }

                return true;
            },
            deletePlan() {
                let plan = _.clone(this.$refs['pricing-plan'].$data);

                this.$emit('delete', plan, this.index);
            },
            reset() {
                this.plan = {};
                this.index = null;
            }
        }
    }
</script>